
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Deposit from '@/store/modules/Deposit';
import GeneralControl from '@/store/modules/GeneralControl';

@Component
export default class Auto extends Vue {
  DepositModule = getModule(Deposit, this.$store);

  GeneralControlModule = getModule(GeneralControl, this.$store);

  isBonus = false;

  name = '-';

  phoneNumber = '-';

  async created() {
    const res = await this.DepositModule.fetchTrue();
    this.name = res[0].name;
    this.phoneNumber = res[0].phoneNumber;
  }

  switchBonus() {
    const { isBonus } = this;
    this.DepositModule.setAutoBonus({
      isBonus,
    });
  }
}
