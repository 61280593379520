<template>
  <div :class="`home -${mode}`">
    <div v-if="!closeFeatureByGameType && isOpenNavbar">
      <Bar
        class="on-Native"
        :expPercentage="expPercentage"
        :detail="detail"
        :mode="mode"
        :avatar="avatar"
        :token="token"
        :event="event"
        :lang="lang"
        :isActiveDropRate="isActiveDropRate"
        :isActiveExpRate="isActiveExpRate"
        :isDropActive="isDropActive"
        :isLevelActive="isLevelActive"
        :isRankActive="isRankActive"
        :isMentananceLevel="isMentananceLevel"
        :gotDuplicate="gotDuplicate"
        :statusDot="statusDot"
        :isLotto="isLotto"
        :toggleBar="toggleBar"
        :isMobileDevice="isMobileDevice"
        :handleIsOpenBar="handleIsOpenBar"
        v-if="detail"
      />
    </div>
    <ModalFrame
      class="on-Native"
    />
    <DepositModal
      class="on-Native"
      :token="token"
      :mode="mode"
      :detail="detail"
      :closeDeposit="closeDeposit"
      :isExitBtn="isExitBtn"
      :backLink="backLink"
      v-if="token"
    />
    <Game
      :onLeaveGame="onLeaveGame"
      :gameLink="gameLink"
      :backLink="backLink"
      :provider="provider"
      :token="token"
      :lobbyVersion="lobbyVersion"
      :linktoLobby="linktoLobby"
      :isChangeGame="isChangeGame"
      v-if="gameLink"/>
    <EventPopUp
      :isShowEventDetail="isShowEventDetail"
      :festivalName="festivalName"
      :borderEvent="borderEvent"
      :evetDetail="evetDetail"
      :fontStyle="fontStyle"
      :closeEvent="closeEvent"
    />
    <DropPopUp
      :dropType="dropType"
      :isDropActive="isDropActive"
      :fontStyle="fontStyle"
      :upEvent="upEvent"
      :dropBorderImg="dropBorderImg"
      :giftUrl="giftUrl"
      :dropAmount="dropAmount"
      :dropMsg="dropMsg"
      :dropMsggift="dropMsggift"
      :lang="lang"
      :dropPoint="dropPoint"
      :popupText="popupText"
    />
    <RankPopUp
      :isRankUp="isRankUp"
      :upEvent="upEvent"
      :fontStyle="fontStyle"
      :level="level"
      :bannerUp="bannerUp"
      :rewardrank="rewardrank"
      :messagerankLevel="messagerankLevel"
      :messagerankGift="messagerankGift"
      :rankUpDetail="rankUpDetail"
      :rewardlevel="rewardlevel"
    />
    <LevelPopUp
      :isLevelUp="isLevelUp"
      :upEvent="upEvent"
      :fontStyle="fontStyle"
      :level="level"
      :bannerUp="bannerUp"
      :rewardlevel="rewardlevel"
      :messagerankLevel="messagerankLevel"
      :messagerankGift="messagerankGift"
    />
    <DuplicatePopUp
      :isDuplicate="isDuplicate"
      :exitFromDuplicate="exitFromDuplicate"
      :fontStyle="fontStyle"
    />
    <UpdateBalance
      :isUpdateBalance="isUpdateBalance"
      :balanceMessage="balanceMessage"
      :enterToRefresh="enterToRefresh"
      :fontStyle="fontStyle"
    />
    <RotateScreen
      :isRotate="isRotate"
    />
    <WrapperRanking
      :token="token"
    />
    <Inventory
      :token="token"
      :lang="lang"
      :handleDot="handleDot"
      :isOpenBar="isOpenBar"
    />
    <StoreExchange
      :token="token"
      :lang="lang"
      :isResetProfile="isResetProfile"
      :modalState="GeneralControlModule.isStoreModalState"
      :promotionPrice="promotionPrice"
    />
    <TouchFullScreen
      v-if="isFishing"
      :handleDs="handleDs"
    />
    <Lotto
      :fontStyle="fontStyle"
      :userToken="token"
      :lang="lang"/>
    <FeatureMenu
      :statusDot="statusDot"
      :userToken="token"
      :fontStyle="fontStyle"
      :promotionPrice="promotionPrice"
      :isRankActive="isRankActive"
      :handleIsOpenBar="handleIsOpenBar"
      :lang="lang"/>
    <!-- <div class="wrapper-AssistiveTouch" v-if="isMobileDevice">
      <VueDragResize
        :isActive="true"
        :isResizable="false"
        :w="50"
        :h="50"
        @clicked="toggleBar+=1"
      >
        <img src="@/assets/AssistiveTouch.png" alt="" class="AssistiveTouch">
      </VueDragResize>
    </div> -->
  </div>
</template>

<script>
import Game from '@/components/Game.vue';
import Bar from '@/components/Bar.vue';
import RotateScreen from '@/components/RotateScreen.vue';
import ModalFrame from '@/components/ModalFrame.vue';
import DepositModal from '@/components/DepositModal.vue';
import FeatureMenu from '@/components/FeatureMenu.vue';
import EventPopUp from '@/components/popup/Event.vue';
import DropPopUp from '@/components/popup/Drop.vue';
import RankPopUp from '@/components/popup/RankUp.vue';
import LevelPopUp from '@/components/popup/LevelUp.vue';
import DuplicatePopUp from '@/components/popup/DuplicateLogin.vue';
import UpdateBalance from '@/components/popup/UpdateBalance.vue';
import TouchFullScreen from '@/components/popup/TouchFullScreen.vue';
import WrapperRanking from '@/components/WrapperRanking.vue';
import Inventory from '@/views/Inventory.vue';
import jwt from 'jsonwebtoken';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';
import Deposit from '@/store/modules/Deposit';
import jwtDecode from 'jwt-decode';
import Vue from 'vue';
import { SOCKET_URL } from '@/utils/api';
import { io } from 'socket.io-client';
import screenfull from 'screenfull';
import StoreExchange from '@/views/StoreExchange.vue';
import Lotto from '@/views/Lotto.vue';
import LottoControl from '@/store/modules/Lotto';
// import VueDragResize from 'vue-drag-resize';

export default {
  components: {
    Game,
    Bar,
    ModalFrame,
    DepositModal,
    RotateScreen,
    EventPopUp,
    DropPopUp,
    RankPopUp,
    LevelPopUp,
    DuplicatePopUp,
    UpdateBalance,
    WrapperRanking,
    Inventory,
    TouchFullScreen,
    StoreExchange,
    Lotto,
    FeatureMenu,
    // VueDragResize,
  },
  data() {
    return {
      DepositModule: {},
      GeneralControlModule: {},
      LottoControlModule: {},
      detail: {},
      rankUpDetail: {},
      event: {},
      setTimeOut: 10000,
      expPercentage: 0,
      level: 0,
      rewardrank: 0,
      rewardlevel: 0,
      isDuplicate: false,
      isRotate: false,
      isUpdateBalance: false,
      isShowEventDetail: false,
      isActiveDropRate: false,
      isActiveExpRate: false,
      isDropActive: false,
      isLevelActive: false,
      isRankActive: false,
      isMentananceLevel: false,
      isRankUp: false,
      isLevelUp: false,
      isOpenNavbar: false,
      closeFeatureByGameType: false,
      toggleBar: 0,
      userToken: '',
      gameLink: '',
      provider: '',
      backLink: '',
      token: '',
      prefix: '',
      mode: '',
      links: '',
      dropType: '',
      dropMsg: '',
      dropAmount: '',
      dropPoint: 0,
      dropMsggift: '',
      dropImg: '',
      dropBorderImg: '',
      lobbyVersion: '',
      avatar: '',
      lang: '',
      messagerankLevel: '',
      messagerankGift: '',
      duplicateMessage: '',
      giftUrl: '',
      evetDetail: '',
      balanceMessage: '',
      borderEvent: '',
      checkBannerEvent: '',
      eventName: '',
      bannerUp: '',
      festivalName: '',
      linktoLobby: '',
      gameType: '',
      ioConnect: {},
      closeDeposit: true,
      statusDot: false,
      isFishing: false,
      isResetProfile: false,
      userLottoProfile: {},
      isCountDown: false,
      popupText: {},
      isLotto: false,
      isExitBtn: false,
      promotionPrice: 0,
      isMobileDevice: false,
      isOpenBar: false,
      isChangeGame: false,
    };
  },
  async beforeCreate() {
    const getTokenByUrl = window.location.search.split('?link=');
    const DecodeWrapperToken = jwt.verify(getTokenByUrl[1], 'wrong-secret');
    if (DecodeWrapperToken.isDemo === true) {
      this.$router.push({ path: '/demo', query: { link: DecodeWrapperToken.link } });
    }
    const userToken = DecodeWrapperToken.token;
    this.userToken = userToken;
    this.axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
    this.ioConnect = await io(SOCKET_URL, {
      auth: { token: `Bearer ${userToken}` },
      transports: ['websocket'],
    });
    this.ioConnect.on('connect_error', (error) => {
      if (error.data.type === 'UnauthorizedError') {
        console.log('User token has expired');
      }
    });
    this.ioConnect.on('Authenticated', async (res) => {
      this.isMentananceLevel = res.data.detail.systemDetail.isLevel;
      this.isDropActive = res.data.detail.agentLobbyDetail.isDrop;
      this.isLevelActive = res.data.detail.agentLobbyDetail.isLevel;
      this.isRankActive = res.data.detail.agentLobbyDetail.isRank;
      this.isLotto = res.data.detail.agentLobbyDetail.isLotto;
      this.isOpenNavbar = res.data.detail.agentLobbyDetail.isOpenNavbar;
      this.isExitBtn = res.data.detail.agentLobbyDetail.isExitBtn || false;
      this.festivalName = res.data.detail.event.nameText[this.lang];
      this.bannerUp = res.data.detail.event.borderImg.level;
      this.checkBannerEvent = res.data.detail.event.name;
      this.borderEvent = res.data.detail.event.borderImg.notiyEvent;
      this.event = res.data.detail.event;
      this.isActiveDropRate = res.data.detail.rateDropItem;
      this.isActiveExpRate = res.data.detail.rateExp;
      this.avatar = res.data.detail.imgAvatar;
      this.$store.dispatch('setProfile', res.data.detail);
      this.promotionPrice = res.data.detail.promotion.store.discount || 0;
      this.onEnterGame(this.ioConnect);
      this.calDetail(res.data.detail);
      this.socketOn(this.ioConnect);
    });
    this.ioConnect.on('invalidToken', (error) => {
      if (error.status.code === 10020) {
        this.gotDuplicate(1002);
      }
    });
    const eventDetail = await this.GeneralControlModule.getEventDetail({ language: this.lang });
    if (eventDetail.data.isActive) {
      this.setEventLocalStorage(eventDetail);
    }
  },
  async created() {
    this.deviceDetect();
    this.DepositModule = getModule(Deposit, this.$store);
    this.GeneralControlModule = getModule(GeneralControl, this.$store);
    this.LottoControlModule = getModule(LottoControl, this.$store);
    window.addEventListener('resize', this.onRotate);
    window.addEventListener('message', async (e) => { await this.onWebViewMessage(e); });
    this.link = this.getParameterByName('link');
    const {
      link,
      provider,
      gameName,
      mode,
      token,
      lang,
      backLink,
      gameType,
      gameCode,
      gameId,
    } = jwt.verify(this.link, 'wrong-secret');
    let _provider = provider;
    if (_provider === 'PG') {
      const linkGame = await this.GeneralControlModule.getLinkLaunchGame(
        { gameCode, gameId, token },
      );
      if (linkGame.search('doctype') > 0 && link === '') {
        // let customLink = linkGame.split('</style>')[1];
        // let customLink = linkGame.replace('<div id="debug"><div class="t">DEBUG</div>', '');
        // customLink = customLink.replace('<div class="content">', '<div class="">');
        // customLink = customLink.replace('<div class="t">DEBUG</div>', '<div class="">');
        // customLink = customLink.replace('<ul class="c" id="_d"></ul>', '<ul></ul>');
        // customLink = customLink.replace('<pre id="text">', '<pre>');
        // customLink = customLink.replace('<div class="title"></div>', '');
        // customLink = customLink.replace(
        //   '#text{font-size:20px;line-height:25px;text-wrap:wrap;margin:0}',
        //   '#text{font-size:20px;line-height:25px;background: #000;text-wrap:wrap;margin:0}',
        // );
        // console.log(customLink);
        this.gameLink = linkGame;
        this.backLink = backLink;
      } else {
        _provider = 'PGC';
        this.gameLink = link === '' ? linkGame : link;
        this.backLink = backLink;
      }
    } else {
      this.gameLink = link;
      this.backLink = this.getParameterByName('from', link);
    }
    this.mode = mode;
    this.prefix = gameName;
    this.provider = _provider && _provider === 'ASKMESLOT' ? 'AMBSLOT' : _provider;
    this.token = token;
    this.lang = lang;
    this.linktoLobby = backLink;
    this.gameType = gameType;
    this.handleFishing(gameType, _provider);
    this.$i18n.locale = this.lang;
    const titleName = document.getElementsByTagName('title');
    titleName[0].text = gameName;
    const userForFill = jwtDecode(this.token);
    await this.DepositModule.getProfile(userForFill);
    const version = await this.GeneralControlModule.getVersion();
    this.lobbyVersion = version[this.mode];
    const getBackLink = await this.GeneralControlModule.getBackLink();
    const _backLink = getBackLink.find((v) => v.provider === this.provider);
    const backLikeProvider = _backLink ? _backLink.backLink : '';
    if (backLikeProvider !== '') {
      if (_provider !== 'PG') this.backLink = this.getParameterByName(backLikeProvider, link);
      if (this.backLink === 'op' || backLikeProvider === 'lobbyURL' || backLikeProvider === 'backurl') {
        this.backLink = `${this.lobbyVersion}?t=${this.token}`;
      }
      if (_provider !== 'PG') this.gameLink = link;
    }
    const depositActiveList = await this.DepositModule.verrifyPayment();
    if (depositActiveList === 1002) {
      this.closeDeposit = false;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.filterDeposit = depositActiveList.filter((v) => v.isActive);
    if (!this.filterDeposit.length) {
      this.closeDeposit = false;
    }
  },
  mounted() {
    window.addEventListener('load', async () => { await this.handleIframeLoad(); });
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        if (/Android|webOS|IEMobile|Opera Mini/i.test(navigator.userAgent)
          && ['FISHING'].includes(this.gameType)
            && ['DRG', 'AMBSLOT'].includes(this.provider)) {
          if (screenfull.isFullscreen) {
            this.isFishing = false;
          } else {
            this.isFishing = true;
          }
        }
      });
    }
    window.addEventListener('keyup', (event) => {
      if (event.code === 'Space' && this.dropType) {
        this.upEvent('drop');
      } else if (event.code === 'Space' && this.isRankUp) {
        this.upEvent('rank');
      } else if (event.code === 'Space' && this.isLevelUp) {
        this.upEvent('level');
      }
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.onRotate);
  },
  methods: {
    handleIframeLoad() {
      this.GeneralControlModule.updateLeaveGameState(true);
      // Do something when the iframe is loaded
    },
    deviceDetect() {
      if (/iPod|iPad|iPhone|Android/i.test(navigator.userAgent)) {
        this.isMobileDevice = true;
      }
    },
    handleFishing(gameType, provider) {
      if (['FISHING'].includes(gameType) && ['SPADEGAMING', 'SLOTXO', 'DRG'].includes(provider)) {
        this.closeFeatureByGameType = true;
        if (/Android|webOS|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          if (['DRG'].includes(provider)) {
            this.isFishing = true;
          }
        }
      }
    },
    socketOn(socket) {
      socket.on('updateRankLevel', (res) => {
        this.isMentananceLevel = res.data.detail.systemDetail.islevel;
        this.isDropActive = res.data.detail.agentLobbyDetail.isDrop;
        this.isLevelActive = res.data.detail.agentLobbyDetail.isLevel;
        this.isRankActive = res.data.detail.agentLobbyDetail.isRank;
        this.isLotto = res.data.detail.agentLobbyDetail.isLotto;
        this.isExitBtn = res.data.detail.agentLobbyDetail.isExitBtn || false;
        if (res.data.detail.isLevelUp && res.data.detail.rankCount === this.detail.rankCount) {
          this.isLevelUp = true;
          this.isRankUp = false;
          this.level = res.data.detail.level;
          window.focus();
          if (res.data.detail.messageLevel && res.data.detail.messageGift !== '') {
            this.messagerankLevel = res.data.detail.messageLevel;
            this.messagerankGift = res.data.detail.messageGift;
            this.level = res.data.detail.level;
            window.focus();
          }
          setTimeout(() => {
            this.isLevelUp = false;
            document.getElementById('game').contentWindow.focus();
          }, this.setTimeOut);
        }
        if (res.data.detail.isLevelUp && res.data.detail.rewardClassUp) {
          this.rankUpDetail.rankCount = res.data.detail.rankCount;
          this.rewardrank = res.data.detail.rewardClassUp;
          this.rewardlevel = res.data.detail.rewardLevelUp;
          this.rankUpDetail.name = res.data.detail.name;
          this.isRankUp = true;
          this.isLevelUp = false;
          this.level = res.data.detail.level;
          window.focus();
          if (res.data.detail.messageLevel && res.data.detail.messageGift !== '') {
            this.messagerankLevel = res.data.detail.messageLevel;
            this.messagerankGift = res.data.detail.messageGift;
            window.focus();
          }
          setTimeout(() => {
            this.isRankUp = false;
            document.getElementById('game').contentWindow.focus();
          }, this.setTimeOut);
        }
        if (res.data.detail.rewardClassUp || res.data.detail.rewardLevelUp !== 0) {
          this.rewardrank = res.data.detail.rewardClassUp;
          this.rewardlevel = res.data.detail.rewardLevelUp;
          window.focus();
        }
        this.calDetail(res.data.detail);
      });
      socket.on('dropGift', (res) => {
        this.dropType = 'dropGift';
        this.dropMsg = res.data.message;
        this.dropAmount = res.data.amount;
        this.dropPoint = res.data.points;
        this.dropMsggift = res.data.messageGift;
        this.dropBorderImg = res.data.borderImg;
        this.giftUrl = res.data.imgUrl;
        window.focus();
        setTimeout(() => {
          this.dropType = '';
          document.getElementById('game').contentWindow.focus();
        }, this.setTimeOut);
      });
      socket.on('disconnectTrigger', (res) => {
        this.duplicateMessage = res.status.message;
        this.gotDuplicate(res.status.code);
      });
      socket.on('updatePlayerBalance', (res) => {
        this.isUpdateBalance = true;
        this.balanceMessage = res.data.message.replace(/(\\r)*\n/g, '<br />');
      });
      socket.on('disconnect', (res) => {
        console.log('disconnect', res);
      });
      socket.on('countdown', (res) => {
        this.LottoControlModule.setIsProgressBar(false);
        if (res.data) {
          const width = 3600 / 100;
          this.LottoControlModule.setCountdown(res.data);
          this.LottoControlModule.setIsPlaying(true);
          const hoursToSeconds = res.data.hours * 60 * 60;
          const minutesToSeconds = res.data.minutes * 60;
          const second = res.data.seconds;
          const totalSecondsTime = hoursToSeconds + minutesToSeconds + second;
          const widthBar = (totalSecondsTime / width);
          this.LottoControlModule.setProgressBarMenu(widthBar);
        }
      });
      socket.on('result', (res) => {
        this.LottoControlModule.setProgressBar(100);
        this.LottoControlModule.setAllTicket(0);
        this.LottoControlModule.setProgressBarMenu(100);
        this.LottoControlModule.setRound(res.data.round);
        this.LottoControlModule.setResultNumber(res.data.result);
        this.LottoControlModule.setIsResultProcess(true);
        this.LottoControlModule.setCountdown({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        this.LottoControlModule.setIsPlaying(false);
        const playTime = 3000;
        setTimeout(() => {
          this.LottoControlModule.setIsResultProcess(false);
          this.LottoControlModule.setMoveingpic('down');
          this.LottoControlModule.setIsGlowing(true);
          this.LottoControlModule.setMoveingpic('up');
          setTimeout(() => {
            this.LottoControlModule.setIsGlowing(false);
          }, playTime);
        }, playTime);
      });
      socket.on('countdown_before_start', (res) => {
        this.LottoControlModule.setIsProgressBar(true);
        if (res.data.cd === 0) {
          this.LottoControlModule.setIsProgressBar(false);
        } else {
          this.LottoControlModule.setIsProgressBar(true);
        }
        this.LottoControlModule.setCountdown({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        this.LottoControlModule.setIsPlaying(false);
        const widthBar = this.LottoControlModule.progressBar - 20;
        this.LottoControlModule.setProgressBar(widthBar);
      });
      socket.on('lucky_player', (res) => {
        this.LottoControlModule.setIsPlaying(false);
        this.dropType = 'luckyPlayer';
        this.giftUrl = res.data.image.imageItem.url;
        this.dropBorderImg = res.data.image.borderImg;
        this.dropAmount = res.data.balance;
        this.dropPoint = 0;
        this.dropMsg = res.data.message;
        this.popupText = res.data.text;
        this.dropMsggift = res.data.text.description[this.lang] || '';
      });
    },
    async setEventLocalStorage(event) {
      const eventBanner = localStorage.getItem('eventBanner');
      if (!eventBanner) {
        localStorage.setItem('eventName', this.checkBannerEvent);
        this.eventName = localStorage.getItem('eventName');
      }
      if (this.eventName === this.checkBannerEvent) {
        const eventShow = localStorage.getItem('countEvent');
        if (eventShow < 1) {
          localStorage.setItem('countEvent', 1);
        }
        if (eventShow >= 1) {
          if (Number(eventShow) === 50) {
            localStorage.setItem('countEvent', 1);
          } else {
            localStorage.setItem('countEvent', Number(eventShow) + 1);
          }
        }
        if (eventShow < 5) {
          if (event.status.code === 0) {
            this.evetDetail = event.data.text.replace(/\n/g, '<br/>');
            if (this.evetDetail) {
              this.isShowEventDetail = true;
            }
            setTimeout(() => {
              this.isShowEventDetail = false;
            }, this.setTimeOut);
          }
        }
      }
    },
    async onWebViewMessage(event) {
      const data = JSON.parse(event.data);
      const condition = !!(event && data);
      this.isChangeGame = true;
      if (condition) {
        const isLeaveGame = ['changeGame', 'changeLanguage'].includes(data.message);
        this.GeneralControlModule.updateChangeGameState(isLeaveGame);
        if (data.message === 'changeGame') {
          await Vue.axios({
            url: `${event.origin}/game_assets/gameName.json`,
            method: 'GET',
          }).then(async (response) => {
            const json = await response.data;
            let label = '';
            const { prefix } = data.data;
            if (json[`${prefix}`]) {
              label = json[`${prefix}`][`${prefix}`];
              if (!label) label = json[`${prefix}`].en;
              document.title = label;
            }
          });
        }
      }
    },
    onRotate() {
      if (/Android|webOS|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        const orientation = window.screen.orientation.type;
        if (this.provider === 'DRG' && this.mode === 'mobile') {
          if (orientation === 'landscape-primary') {
            this.isRotate = true;
          } else {
            this.isRotate = false;
          }
        }
      }
    },
    calDetail(detail) {
      this.detail = detail;
      const currentExpAmount = detail.nowExp - detail.startExp;
      const currentExpLength = detail.endExp - detail.startExp;
      this.expPercentage = (currentExpAmount / currentExpLength) * 100;
    },
    onLeaveGame() {
      this.ioConnect.emit('leaveRoom', { prefix: this.prefix }, (res) => {
        console.log('leaveRoom', res);
      });
    },
    onEnterGame(socket) {
      socket.emit('enterRoom', { prefix: this.prefix }, (res) => {
        console.log('enterRoom', res);
      });
    },
    getParameterByName(name, url = window.location.href) {
      // eslint-disable-next-line no-useless-escape
      const replace = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${replace}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    upEvent(type) {
      if (type === 'rank') {
        this.isRankUp = false;
        document.getElementById('game').contentWindow.focus();
      } else if (type === 'drop') {
        this.dropType = '';
        document.getElementById('game').contentWindow.focus();
      } else {
        this.isLevelUp = false;
        document.getElementById('game').contentWindow.focus();
      }
      this.dropAmount = 0;
      this.dropPoint = 0;
    },
    fontStyle() {
      if (this.lang === 'th') {
        return 'thai-font';
      // eslint-disable-next-line no-else-return
      } else {
        return 'eng-font';
      }
    },
    closeEvent() {
      this.isShowEventDetail = false;
    },
    async gotDuplicate(code) {
      if (code === 1002) {
        this.isDuplicate = true;
        if (this.linktoLobby.includes('pgslot')) {
          delete Vue.axios.defaults.headers.common.Authorization;
          const version = await this.GeneralControlModule.getVersionLobby();
          const url = version[`${this.mode}Login`];
          this.linktoLobby = url;
        }
      }
    },
    async exitFromDuplicate() {
      delete Vue.axios.defaults.headers.common.Authorization;
      window.location.href = this.linktoLobby;
    },
    async enterToRefresh() {
      this.$router.go(0);
    },
    handleDot(event) {
      if (event !== 0) {
        this.statusDot = true;
      } else {
        this.statusDot = false;
      }
    },
    handleDs() {
      document.documentElement.requestFullscreen();
      this.isFishing = false;
    },
    handleIsOpenBar(data) {
      if (data.isOpen) {
        this.isOpenBar = true;
      } else {
        this.isOpenBar = false;
      }
    },
  },
  watch: {
    handleFS(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.on-Native {
  @media screen and (max-width: 300px){
    display: none;
  }
}

.handleDSFishing {
  position: absolute;
  top: -50%;
  left: -50%;
  transform: translate(-50%, -50%);
  z-index: 99999999999;
}

.wrapper-AssistiveTouch {
  position: absolute;
  z-index: 999;
  cursor: pointer;
}

.AssistiveTouch {
  width: 50px;
  height: 50px;
}
</style>
